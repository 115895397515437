type Props = {
  children: React.ReactNode;
  className?: string;
};

function Header({ children, className }: Props) {
  return (
    <h2 className={`${className} text-center text-[28px] font-semibold text-white`}>{children}</h2>
  );
}

export default Header;
