import { NextPage } from 'next';
import Image from 'next/image';
import { NextRouter } from 'next/router';

const flavorTexts = {
  signup: (
    <>
      get selling’ tasty <br />
      food online <br />
      in a jiffy… yum.
    </>
  ),
  signin: (
    <>
      mmm… let’s cook <br />
      Something tasty.
    </>
  ),
  signout: (
    <>
      yum… that was a <br />
      tasty meal you <br />
      just cooked
    </>
  ),
  'forgot-password': (
    <>
      at least you {"didn't"} <br />
      lose your keys...
    </>
  ),
  'password-reset': (
    <>
      it was in your <br />
      back pocket the <br />
      whole time.
    </>
  ),
  default: (
    <>
      mmm… let’s cook <br />
      Something tasty.
    </>
  ),
};

type AuthRoute = 'signup' | 'signin' | 'forgot-password' | 'password-reset' | 'signout';

function getAuthLayout(page: NextPage, router: NextRouter) {
  const [root, path] = router.pathname.split('/');

  return (
    <main className="relative flex w-full flex-1 justify-between bg-neutral-900 ">
      <section className="flex h-full w-full flex-col justify-between lg:w-6/12 lg:pb-0">
        <div className="mt-4 flex items-center justify-center">
          <Image src={'/logo-white.svg'} alt="Logo" width={180} height={35} />
        </div>

        <div className="flex w-full items-center text-white">{page}</div>
        <div>
          <div className="flex hidden min-h-[80px] min-w-full flex-col bg-[url('/img/logindefault.jpg')] bg-cover bg-left-top"></div>{' '}
          {/*Background Image on Mobile*/}
        </div>
      </section>
      <section className="hidden h-full w-full flex-col justify-between bg-[url('/img/logindefault.jpg')] bg-cover lg:flex lg:w-6/12 lg:pb-0"></section>
    </main>
  );
}

export default getAuthLayout;
